/* eslint-disable no-console */
import Vue from 'vue';
import { calcStoreTotalPrice, getPriceFromSelection } from './../service/priceCalculator/index.js';
// import MOCK_SELECTEDPRODUCTS from './mock_selectedProducts.js';
import RENDERVIEW from './renderView.js';

// Duplicate filemaps for custom slots
// <slotIdToCopy>:<roomId>_<customSlotSlug>
const duplicateKeys = {
  '405_78': '228_hallway_floor',
  '414': '228_hallway_floor',
};

for (const key in RENDERVIEW.fileMap) {
  const mappings = RENDERVIEW.fileMap[key];
  for (const sourceKey in duplicateKeys) {
    if (Object.keys(mappings).includes(sourceKey)) {
      const newKey = duplicateKeys[sourceKey];
      const newValue = mappings[sourceKey];
      mappings[newKey] = newValue;
    }
  }
}

let storeChangedTimeout;
export const store = Vue.observable({
  config: {
    environment: 'production',
    enableConfigurationSave: true,
    renderingsPath:
      'https://storbpdkonfigurator.blob.core.windows.net/storcontbpdkonfigurator/renderings/',
    // renderingsPath: 'https://storbpdkonfigurator.azureedge.net/storcontbpdkonfigurator/renderings/',
    // renderingsPath: '/media/renders/',
  },
  featureDetection: {},
  ui: {
    projectInfoIsActive: false,
    projectLegalIsActive: true,
  },
  renderView: RENDERVIEW, // not the best place to put it since it doesn't have to be reactive/observable
  apiData: {
    apartment: {},
    project: {},
    rooms: {},
    products: {},
    user: {},
  },
  userSelectionData: {
    totalPrice: 0,
    basePrice: 0,
    changed: 0,
    cascades: [
      {
        type: 'tilingHeight',
        triggers: [
          // slotId is safer, in case someone changes title of slotSlugs in CMS
          '296', // Fliesen im Duschbereich
          '5229', // Fliesen im Waschbeckenbereich
          '5232', // Fliesen im Waschbeckenbereich
          '5235', // Fliesen im restlichen Raum
        ],
        // slotId is safer, in case someone changes title of slotSlugs in CMS
        targets: ['36', '15499'],
      },
    ],
  },
});

export const mutations = {
  addSelection(_roomSlug, _slotSlug, _selection) {
    // slot cascade - nasty hard-coded stuff...
    const slotId = _slotSlug.replace(/(^\d+)(.+$)/i, '$1');
    store.userSelectionData.cascades.forEach(cascade => {
      if (cascade.triggers.includes(slotId)) {
        console.log('[cascades]', 'triggered', cascade.type);
        switch (cascade.type) {
          case 'tilingHeight':
            var totalArea = 0.0;
            var productId = _selection.productIds[_selection.productIds.length - 1];
            var product = store.apiData.products.find(product => {
              return product.id == productId;
            });

            // calculate proper value for wall tile area
            var slot = store.lookUps.slotBySlotSlug[_slotSlug];

            console.log('[cascades]', productId, product, _selection);

            var roomHeight = slot.attributes.roomHeight ?? 2.5;
            var currentArea =
              slot.attributes.tilingArea *
              (product.attributes.tilingHeight === 2.5
                ? 1
                : product.attributes.tilingHeight / roomHeight);

            _selection.area = currentArea;

            // get all source slots where the individual quantities (tilingAreas) are stored
            cascade.triggers.forEach(id => {
              const slotSlug = store.lookUps.slotSlugBySlotId[_roomSlug][id];
              const selection = store.userSelectionData.products[_roomSlug].find(selection => {
                return selection.slotSlug == slotSlug;
              });

              if (slotSlug === _selection.slotSlug) {
                // new selection hasn't yet been written to store, so we must redirect that data here
                console.log('[cascades]', 'new source', slotSlug, _selection.area);
                totalArea += _selection.area;

                // clear price, just to be sure
                _selection.totalPrice = 0.0;
              } else if (selection !== undefined) {
                // take other selections from store
                console.log('[cascades]', 'source', slotSlug, selection.area);
                totalArea += selection.area;

                // clear price, just to be sure
                selection.totalPrice = 0.0;
              }
            });

            // total area needs to be the actual calculated value plus 1 sqm extra
            totalArea += 0.5;

            cascade.targets.forEach(targetId => {
              // find target slot in selection to write quantity (tilingArea) to.
              var targetSlotSlug = store.lookUps.slotSlugBySlotId[_roomSlug][targetId];
              var targetSlot = store.userSelectionData.products[_roomSlug].find(slot => {
                return slot.slotSlug == targetSlotSlug;
              });

              if (!targetSlot) {
                console.log('[cascades]', 'target doesn’t exist', targetId);
                return;
              }

              console.log('[cascades]', 'target', _roomSlug, targetSlotSlug, targetSlot);

              targetSlot.quantity = totalArea;
              targetSlot.totalPrice = getPriceFromSelection(targetSlot);
            });

            break;
        }
      }
    });

    const foundSlotSlugIndexInStore = store.userSelectionData.products[_roomSlug].findIndex(
      _element => _element.slotSlug == _slotSlug
    );
    if (foundSlotSlugIndexInStore === -1) {
      //add product - this should actually never happen any more
      store.userSelectionData.products[_roomSlug].push(_selection);
      console.log('[store:] addSelection() - added new _selection: ', _selection);
    } else {
      // inject previous data
      if (_selection.type === undefined)
        _selection.type =
          store.userSelectionData.products[_roomSlug][foundSlotSlugIndexInStore].type;

      if (_selection.quantity === undefined)
        _selection.quantity =
          store.userSelectionData.products[_roomSlug][foundSlotSlugIndexInStore].quantity;

      if (_selection.basePrice === undefined)
        _selection.basePrice =
          store.userSelectionData.products[_roomSlug][foundSlotSlugIndexInStore].basePrice;

      //replace product
      store.userSelectionData.products[_roomSlug][foundSlotSlugIndexInStore] = _selection;
      console.log('[store:] addSelection() - replaced _selection: ', _selection);
    }
    //

    // recalculate prices for slots that reference this slot
    if (store.lookUps.referencedSlots[_slotSlug] !== undefined) {
      store.lookUps.referencedSlots[_slotSlug].forEach(referencedSlotSlug => {
        const referencedRoomSlug = store.lookUps.roomSlugBySlotSlug[referencedSlotSlug];
        const referencedSlotIndex = store.userSelectionData.products[referencedRoomSlug].findIndex(
          slot => {
            return slot.slotSlug == referencedSlotSlug;
          }
        );
        const referencedPrice = getPriceFromSelection(
          store.userSelectionData.products[referencedRoomSlug][referencedSlotIndex]
        );

        store.userSelectionData.products[referencedRoomSlug][
          referencedSlotIndex
        ].totalPrice = referencedPrice;
      });
    }

    if (storeChangedTimeout) {
      clearTimeout(storeChangedTimeout);
    }
    storeChangedTimeout = setTimeout(() => {
      calcStoreTotalPrice();
      store.userSelectionData.changed++;
    }, 25);
  },

  setTotalPrice(_totalPrice) {
    store.userSelectionData.totalPrice = _totalPrice;
  },

  setBasePrice(_basePrice) {
    store.userSelectionData.basePrice = _basePrice;
  },

  setProjectLegalIsActive(_isActive) {
    store.ui.projectLegalIsActive = _isActive;
  },

  setProjectInfoIsActive(_isActive) {
    store.ui.projectInfoIsActive = _isActive;
  },
};

export const getters = {
  getCascades() {
    return store.userSelectionData.cascades;
  },
  getSelection(_roomSlug, _slotSlug) {
    return store.userSelectionData.products[_roomSlug].find(_element => {
      return _element.slotSlug == _slotSlug;
    });
  },

  getSelectionBySlotSlug(_slotSlug) {
    const _roomSlug = store.lookUps.roomSlugBySlotSlug[_slotSlug];
    // console.error(_roomSlug, _slotSlug);
    return store.userSelectionData.products[_roomSlug].find(_element => {
      return _element.slotSlug == _slotSlug;
    });
  },

  getProducts() {
    return store.apiData.products;
  },

  getProductById(id) {
    return store.apiData.products.find(product => product.id === id);
  },

  getSlotBySlotSlug(slotSlug) {
    return store.lookUps.slotBySlotSlug[slotSlug];
  },

  getUserSelectionProducts() {
    return store.userSelectionData.products;
  },

  getUserSelectionProductsByRoom(_roomSlug) {
    return store.userSelectionData.products[_roomSlug];
  },

  getUserSelectionProductsInOneArray() {
    const selections = [];

    for (const room of Object.values(store.userSelectionData.products)) {
      room.forEach(slot => {
        selections.push(slot);
      });
    }

    return selections;
  },

  // get all slots that are currently enabled due to switch fields
  // this could be cached - not sure that this implementation will cache though
  getUserSelectionSwitchSelected() {
    const rooms = store.userSelectionData.products;
    let selected = [];

    Object.keys(rooms).forEach(roomSlug => {
      rooms[roomSlug].forEach(slot => {
        const slugs = slot.productSlotSlugs;
        if (slugs !== undefined && slugs !== null && slugs.length > 0) {
          // console.log('add', roomSlug, slugs);
          selected = selected.concat(slugs);
        }
      });
    });

    // console.log(selected);

    return selected;
  },

  // get all slots that are currently disabled due to switch fields
  // this could be cached - not sure that this implementation will cache though
  getUserSelectionSwitchUnselected() {
    const rooms = store.userSelectionData.products;
    let unselected = [];
    // let temp = {};

    Object.keys(rooms).forEach(roomSlug => {
      rooms[roomSlug].forEach(slot => {
        const slugs = slot.unselectedSlotSlugs;
        if (slugs !== undefined && slugs !== null && slugs.length > 0) {
          // console.log('add', roomSlug, slugs);
          unselected = unselected.concat(slugs);
          // slugs.forEach(slug => {
          //   temp[slug] = true;
          // });
        }
      });
    });

    // Object.keys(temp).forEach(slug => {
    //   unselected.push(slug);
    // });

    // console.log(unselected);

    return unselected;
  },

  getRoomBySlug(_roomSlug) {
    return store.apiData.rooms.find(_room => _room.slug === _roomSlug);
  },

  getConflictingProductsByRoom(_roomSlug, _slotSlug, _key, _value) {
    return store.userSelectionData.products[_roomSlug]
      .filter(_product => {
        //if product has attributes, has the current and is not himself by slotSlug
        if (
          _product.attributes &&
          _product.attributes[_key] !== undefined &&
          _product.slotSlug !== _slotSlug
        ) {
          return _product.attributes[_key] !== _value;
        }
      })
      .map(_product => {
        return {
          product: _product,
          roomSlug: _roomSlug,
          attribute: _key,
        };
      });
  },

  getFullPrice() {
    return store.userSelectionData.totalPrice;
  },
  getDeltaPrice() {
    return store.userSelectionData.totalPrice - store.userSelectionData.basePrice;
  },
  getBasePrice() {
    return store.userSelectionData.basePrice;
  },
};
